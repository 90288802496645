import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { MeatbonesScreen1 } from "../../assets/images/Images";
import Container from "../../components/Container";
import { Block, GridWrapper, Image } from "../../components/GridComponents";
import Heading from "../../components/Heading";
import InnerHTML from "../../components/InnerHTML";
import Paragraph from "../../components/Paragraph";
import Spacer from "../../components/Spacer";
import { t } from "../../intl/getTranslation";
import { theme } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import NumberedBlocks, { NumberedBlockT } from "../general/NumberedBlocks";

const AboutSection: FC = () => {
  const intl = useIntl();
  const blocks: NumberedBlockT[] = [
    {
      text: putEmptyLineInsteadSpace(
        intl.formatMessage({ id: "meatbones.about_section.block_1" }),
        3
      ),
      width: 12
    },
    {
      text: putEmptyLineInsteadSpace(
        intl.formatMessage({ id: "meatbones.about_section.block_2" }),
        3
      ),
      width: 12
    },
    {
      text: intl.formatMessage({ id: "meatbones.about_section.block_3" }),
      width: 12
    },
    {
      text: intl.formatMessage({ id: "meatbones.about_section.block_4" }),
      width: 12
    }
  ];

  return (
    <Container>
      <Spacer mobileSize="large" size="extraLarge" />
      <Heading
        title={t("meatbones.about_section.title", intl)}
        symbolColor={theme.colors.darkText}
      />
      <Spacer size="extraSmall" />
      <Paragraph
        paragraph={
          <InnerHTML content={t("meatbones.about_section.paragraph", intl)} />
        }
        maxWidth={450}
        color={theme.colors.darkText}
      />
      <Spacer mobileSize="medium" size="large" />
      <GridWrapper>
        <Block paddingRight={30} justifyContent="flex-start" width={4}>
          <NumberedBlocks blocks={blocks} verticalDirection={true} />
          <Spacer mobileSize="medium" size="large" />
        </Block>
        <Block justifyContent="flex-start" width={8}>
          <Image src={MeatbonesScreen1} alt="Meat & Bones homepage" />
        </Block>
      </GridWrapper>
    </Container>
  );
};

export default AboutSection;
