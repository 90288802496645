import React, { FC } from "react";

import Layout from "../../components/Layout";
import Introduction from "../../partials/general/Introduction";
import SuccessStoriesFooter from "../../partials/general/SuccessStoriesFooter";
import AboutSection from "../../partials/MeatBones/AboutSection";
import InterestSection from "../../partials/MeatBones/InterestSection";

const Meatbones: FC = () => {
  const ID = "meatbones";

  return (
    <Layout
      helmetKey={ID}
      emptyMenu={true}
      blackMenu={true}
      lightColorVariant
      menuIconType="/success-stories"
      render={(): React.ReactChild => (
        <>
          <Introduction caseStudyId={ID} />
          <AboutSection />
          <InterestSection />
          <SuccessStoriesFooter />
        </>
      )}
    />
  );
};

export default Meatbones;
