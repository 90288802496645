import { useIntl } from "gatsby-plugin-intl";
import React, { FC } from "react";

import { MeatbonesScreen2 } from "../../assets/images/Images";
import Container from "../../components/Container";
import Spacer from "../../components/Spacer";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";
import { putEmptyLineInsteadSpace } from "../../utils/putEmptyLineInsteadSpace";
import HeadingBlock from "../general/HeadingBlock";
import NumberedBlocks, { NumberedBlockT } from "../general/NumberedBlocks";

const Wrapper = styled.div`
  position: relative;
  ${({ theme }): string[] => [theme.media.maxM]} {
    margin-top: ${rem(50)};
  }
`;

const GreyBlock = styled.div`
  background-color: #f7f7f7;
  position: absolute;
  top: 13%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: ${rem(380)};
  ${({ theme }): string[] => [theme.media.maxLg]} {
    top: 10%;
    height: ${rem(300)};
  }
  ${({ theme }): string[] => [theme.media.maxM]} {
    height: ${rem(190)};
  }
  ${({ theme }): string[] => [theme.media.maxMD]} {
    display: none;
  }
`;

const Screen = styled.img`
  width: calc(100% + ${rem(100)});
  position: relative;
  top: ${rem(-100)};
  left: ${rem(-100)};
  @media screen and (max-width: 1439px) {
    top: auto;
    left: auto;
  }
`;

const InterestSection: FC = () => {
  const intl = useIntl();

  const blocks: NumberedBlockT[] = [
    {
      text: intl.formatMessage({ id: "meatbones.interest_section.block_6" }),
      width: 3
    },
    {
      text: putEmptyLineInsteadSpace(
        intl.formatMessage({ id: "meatbones.interest_section.block_7" }),
        3
      ),
      width: 3
    },
    {
      text: intl.formatMessage({ id: "meatbones.interest_section.block_8" }),
      width: 3
    },
    {
      text: intl.formatMessage({ id: "meatbones.interest_section.block_9" }),
      width: 3
    }
  ];

  return (
    <>
      <Container>
        <Spacer size="extraLarge" />
        <HeadingBlock
          heading={intl.formatMessage({
            id: "meatbones.interest_section.title"
          })}
          pre={intl.formatMessage({ id: "meatbones.interest_section.pre" })}
          paragraph={intl.formatMessage({
            id: "meatbones.interest_section.paragraph"
          })}
        />
      </Container>
      <Wrapper>
        <Container>
          <Screen src={MeatbonesScreen2} alt="Meat & Bones design" />
        </Container>
        <GreyBlock />
      </Wrapper>
      <Container>
        <NumberedBlocks blocks={blocks} initOrder={6} />
        <Spacer mobileSize="small" size="extraLarge" />
      </Container>
    </>
  );
};

export default InterestSection;
