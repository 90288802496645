import React, { FC } from "react";

import { Block, GridWrapper } from "../../components/GridComponents";
import InnerHTML from "../../components/InnerHTML";
import styled from "../../styling/styled";
import { rem } from "../../styling/theme";

const NumberedBlock = styled.div`
  width: 100%;
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    font-size: ${rem(16)};
    padding: ${rem(20)} 0 ${rem(20)} ${rem(60)};
  }
`;

const Pre = styled.span`
  font-family: "Overpass Mono";
  display: block;
  font-size: ${rem(30)};
  font-weight: 700;
  margin-bottom: ${rem(30)};
  ${({ theme }): string[] => [theme.media.maxM]} {
    position: absolute;
    left: ${rem(0)};
  }
`;

const BottomLine = styled.div`
  width: ${rem(120)};
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }): string => theme.colors.borderGray};
  margin-top: ${rem(35)};
  margin-bottom: ${rem(35)};
`;

// width is similar as bootstrap grid system
export type NumberedBlockT = {
  text: string;
  width: number;
};

type NumberedBlocksT = {
  blocks: NumberedBlockT[];
  verticalDirection?: boolean;
  initOrder?: number;
};

const NumberedBlocks: FC<NumberedBlocksT> = ({
  blocks,
  verticalDirection,
  initOrder = 1
}) => {
  const EDGES = 40;

  return (
    <GridWrapper opositeMargin={EDGES}>
      {blocks.map((block, index) => (
        <Block
          width={block.width}
          key={index}
          paddingRight={EDGES}
          paddingLeft={EDGES}
          withBorder={!verticalDirection}
          flexDirection="row"
        >
          <NumberedBlock>
            <Pre>{"0" + (index + initOrder).toString()}</Pre>
            <InnerHTML content={block.text} />
            {verticalDirection && blocks.length !== index + 1 && <BottomLine />}
          </NumberedBlock>
        </Block>
      ))}
    </GridWrapper>
  );
};

export default NumberedBlocks;
